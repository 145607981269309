import logo from './logo.svg';
import './App.css';
import { Routes } from 'react-router-dom';
import { Routing } from './components/Everythingatonce';


function App() {
  

  return (
   <>
   
   <Routing/>

  </>
  );
}
export default App;
